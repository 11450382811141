'use strict';

var $TypeError = require('es-errors/type');
var Get = require('./Get');
var ToLength = require('./ToLength');
var Type = require('./Type');

// https://262.ecma-international.org/11.0/#sec-lengthofarraylike

module.exports = function LengthOfArrayLike(obj) {
  if (Type(obj) !== 'Object') {
    throw new $TypeError('Assertion failed: `obj` must be an Object');
  }
  return ToLength(Get(obj, 'length'));
};

// TODO: use this all over